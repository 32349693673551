import { io } from 'socket.io-client';
import store from "@/store/store";

class SocketioService {
    socket;
    socketlist = '';
    room = ''
    id = 0;
    constructor() {}

    setupSocketConnection(sport,id) {
        if(this.socket == undefined) {
            this.socket = io(`https://sockets.rahisibet.com/`);

            this.socket.on('connect', () => {
                this.socket.emit('join', sport);
                console.log('Socket ' +sport+ ' connected!');
            })
    
            this.socket.on('connect_error', () => {
                console.log('Socket connection error!');
                this.socket = io(`https://sockets.rahisibet.com/`);
            })
        } else {
            
            this.socket.emit('unsubscribe', this.room);
            this.socket.emit('join', sport);
        }
        this.id = id
        this.room = sport
       
    }

    getLiveGames() {
        this.socket.on('game', (message) => {
            let res = JSON.parse(message)
            store.dispatch("set_live_game",{game:JSON.parse(res.data),sport:this.id});
        });

        this.socket.on('betstop', (message) => {
            console.log('betstop',JSON.parse(message));
            store.dispatch("set_live_betstop",JSON.parse(message))
        })
        
        this.socket.on('ended', (message) => {
            console.log('ended',JSON.parse(message));
            store.dispatch("set_live_ended",JSON.parse(message))
        })
    }

    getSingleLiveGames() {
        this.socket.on('game', (message) => {
            let res = JSON.parse(message)
            store.dispatch("set_slive_game",{game:JSON.parse(res.data),sport:this.id});
        });

        this.socket.on('betstop', (message) => {
            console.log('betstop',message);
        })

        this.socket.on('ended', (message) => {
            console.log('ended',message);
        })
    }

    changeChannel(obj) {
        this.id = obj.id
        this.room = obj.room
        if( obj.room != obj.sport) {
            this.socket.emit('unsubscribe', obj.room);
            this.socket.emit('join', obj.sport);

            this.socket.on('disconnect', () => {
                console.log('Socket ' + obj.sport + ' disconnected!');
            })

            this.socket.on('connect', () => {
                console.log('Socket ' + obj.sport + ' reconnected!');
            })
        } else {
            console.log(obj);
        }
    }

    changeSChannel(obj) {
        if(this.socket == undefined) {
            this.socket = io(`https://sockets.rahisibet.com/`);
        }

        this.socket.emit('join', `game-${obj.sport}`);

        this.socket.on('disconnect', () => {
            console.log('Socket ' + obj.sport + ' disconnected!');
        })

        this.socket.on('connect', () => {
            console.log('Socket ' + obj.sport + ' reconnected!');
        })
        
    }

    leaveRoom() {
        this.socket.emit('unsubscribe',this.room);
    }

    leaveSRoom() { 
        
        this.socket.emit('left');
    }

}

export default new SocketioService();